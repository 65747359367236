// <copyright file="floors.effect.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { FloorsService } from '../../../services';
import * as floorsActions from '../../actions';

@Injectable()
export class FloorsEffect {
    public doLoadFloorsData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(floorsActions.floorsActionTypes.FLOORS_LOAD),
            map((action: floorsActions.FloorsLoadAction) => action['payload']),
            mergeMap(state =>
                this.floorsService.getFloors(state.customerId, state.venueId).pipe(
                    map(data => new floorsActions.FloorsLoadSuccessAction(data)),
                    catchError(error => of(new floorsActions.FloorsLoadFailAction(error))),
                ),
            ),
        ),
    );

    constructor(private actions: Actions, private floorsService: FloorsService) {}
}
