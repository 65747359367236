// <copyright file="customers.selectors.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromCustomers from '../../reducers/customers/customers.reducer';

export const getCustomersState = (state: fromFeature.ISharedState) => state.customers;

export const getCustomersData = createSelector(
    getCustomersState,
    fromCustomers.getCustomers
);

export const getCustomersLoading = createSelector(
    getCustomersState,
    fromCustomers.getLoading
);

export const getCustomersSuccess = createSelector(
    getCustomersState,
    fromCustomers.getSuccess
);

export const getCustomersFailed = createSelector(
    getCustomersState,
    fromCustomers.getFailed
);
