// <copyright file="footer.component.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'portal-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    public currentYear = moment().year();

    public showICP(): boolean {
        return window.location.href.indexOf('.interact-lighting.com.cn') > -1;
    }
}
