// <copyright file="layout.container.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Component } from '@angular/core';

@Component({
  selector: 'dp-layout',
  templateUrl: 'layout.container.html',
  styleUrls: ['layout.container.scss']
})

export class LayoutContainerComponent { }
