// <copyright file="floors.action.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { FloorSimpleDTO } from './../../../dtos';
import { Action } from '@ngrx/store';
import { type } from '../../../utility/type/type.helper';

export const floorsActionTypes = {
    FLOORS_LOAD: type('[Floors] Load'),
    FLOORS_LOAD_FAIL: type('[Floors] Load Fail'),
    FLOORS_LOAD_SUCCESS: type('[Floors] Load Success'),
    FLOORS_CLEAR: type('[Floors] Clear')
};

export class FloorsLoadAction implements Action {
    public type = floorsActionTypes.FLOORS_LOAD;

    constructor(public payload: { customerId: number, venueId: number }) { }
}

export class FloorsLoadSuccessAction implements Action {
    public type = floorsActionTypes.FLOORS_LOAD_SUCCESS;

    constructor(public payload: Array<FloorSimpleDTO>) { }
}

export class FloorsLoadFailAction implements Action {
    public type = floorsActionTypes.FLOORS_LOAD_FAIL;

    constructor(public payload: any) { }
}

export class FloorsClearAction implements Action {
    public type = floorsActionTypes.FLOORS_CLEAR;

    constructor() { }
}

export type FloorsActions
    = FloorsLoadAction
    | FloorsLoadFailAction
    | FloorsLoadSuccessAction
    | FloorsClearAction;
