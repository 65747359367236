// <copyright file="layouts.selectors.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromLayouts from '../../reducers/layouts/layouts.reducer';

export const getLayoutsState = (state: fromFeature.ISharedState) => state.layouts;

export const getLayoutsData = createSelector(
    getLayoutsState,
    fromLayouts.getLayouts
);

export const getLayoutsLoading = createSelector(
    getLayoutsState,
    fromLayouts.getLoading
);

export const getLayoutsSuccess = createSelector(
    getLayoutsState,
    fromLayouts.getSuccess
);

export const getLayoutsFailed = createSelector(
    getLayoutsState,
    fromLayouts.getFailed
);
