// <copyright file="venues.reducer.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { VenueSimpleDTO } from './../../../dtos';
import * as actions from '../../actions/venues/venues.action';

export interface IVenueState {
    loading: boolean;
    success: boolean;
    failed: boolean;
    venues: Array<VenueSimpleDTO>;
}

export const INITIAL_STATE: IVenueState = {
    venues: undefined,
    failed: false,
    loading: false,
    success: false,
};

export function reducer(state = INITIAL_STATE, action: actions.VenuesActions): IVenueState {
    if (!action)  {
        return state;
    }

    switch (action.type) {
        case actions.venuesActionTypes.VENUES_LOAD: {
            return Object.assign({}, state, {
                loading: true
            });
        }

        case actions.venuesActionTypes.VENUES_LOAD_SUCCESS: {
            const loadAction = action as actions.VenuesLoadSuccessAction;
            return Object.assign({}, state, {
                venues: loadAction.payload,
                success: true,
                failed: false,
                loading: false
            });
        }

        case actions.venuesActionTypes.VENUES_LOAD_FAIL: {
            return Object.assign({}, state, {
                venues: undefined,
                success: false,
                failed: true,
                loading: false
            });
        }

        case actions.venuesActionTypes.VENUES_CLEAR: {
            return Object.assign({}, state, INITIAL_STATE);
        }

        default: {
            return state;
        }
    }
}

export const getVenues = (state: IVenueState) => state.venues;
export const getLoading = (state: IVenueState) => state.loading;
export const getSuccess = (state: IVenueState) => state.success;
export const getFailed = (state: IVenueState) => state.failed;
