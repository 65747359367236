// <copyright file="licenses.effect.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LicensesService } from '../../../services';
import * as licensesActions from '../../actions';

@Injectable()
export class LicensesEffect {
    public doLoadLicensesData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(licensesActions.licensesActionTypes.LICENSES_LOAD),
            map((action: licensesActions.LicensesLoadAction) => action.payload),
            mergeMap(state =>
                this.licensesService.getLicenses(state.customerId, state.venueId).pipe(
                    map(data => new licensesActions.LicensesLoadSuccessAction(data)),
                    catchError(error => of(new licensesActions.LicensesLoadFailAction(error))),
                ),
            ),
        ),
    );

    constructor(private actions: Actions, private licensesService: LicensesService) {}
}
