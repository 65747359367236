// <copyright file="type-check.interceptor.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
    } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TypeCheckInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map(event => {
            if (event instanceof HttpResponse) {
                if (event.body === '') {
                    const res = new HttpResponse({
                        body: undefined,
                        headers: event.headers,
                        status: event.status,
                        statusText: event.statusText,
                        url: event.url
                    });
                    return res;
                } else {
                    return event;
                }
            }
        }));
    }
}
