// <copyright file="customers.effect.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CustomersService } from '../../../services';
import * as customersActions from '../../actions';

@Injectable()
export class CustomersEffect {
    public doLoadCustomersData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(customersActions.customersActionTypes.CUSTOMERS_LOAD),
            map((action: customersActions.CustomersLoadAction) => action['payload']),
            mergeMap(state =>
                this.customersService.getCustomers().pipe(
                    map(data => new customersActions.CustomersLoadSuccessAction(data)),
                    catchError(error => of(new customersActions.CustomersLoadFailAction(error))),
                ),
            ),
        ),
    );
    constructor(private actions: Actions, private customersService: CustomersService) {}
}
