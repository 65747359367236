// <copyright file="customers.sandbox.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import * as store from '../../store';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromCustomers from '../../store/actions';
import { ErrorHandlerService } from '../../utility/error-handler/error-handler.service';
import { Subscription } from 'rxjs';

@Injectable()
export class CustomersSandbox implements OnDestroy {
    public customers = this.appState.pipe(select(store.getCustomersData));
    public customersLoading = this.appState.pipe(select(store.getCustomersLoading));
    public customersFailed = this.appState.pipe(select(store.getCustomersFailed));
    public customersSuccess = this.appState.pipe(select(store.getCustomersSuccess));

    private errorSubscriptions: Subscription;

    constructor(protected appState: Store<store.ISharedState>, private errorHandlerService: ErrorHandlerService) {
        this.setupErrorSubscription();
    }

    ngOnDestroy(): void {
        if (this.errorSubscriptions) {
            this.errorSubscriptions.unsubscribe();
        }
    }

    public loadCustomers(): void {
        this.appState.dispatch(new fromCustomers.CustomersLoadAction());
    }

    private setupErrorSubscription(): void {
        this.errorSubscriptions = this.customersFailed.subscribe((failed: boolean) => {
            if (failed) { this.errorHandlerService.addToErrors('Something went wrong while loading the customers'); }
        });
    }
}
