// <copyright file="floors.sandbox.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import * as store from '../../store';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromFloors from '../../store/actions';
import { ErrorHandlerService } from '../../utility/error-handler/error-handler.service';
import { Subscription } from 'rxjs';

@Injectable()
export class FloorsSandbox implements OnDestroy {
    public floors = this.appState.pipe(select(store.getFloorsData));
    public floorsLoading = this.appState.pipe(select(store.getFloorsLoading));
    public floorsFailed = this.appState.pipe(select(store.getFloorsFailed));
    public floorsSuccess = this.appState.pipe(select(store.getFloorsSuccess));

    private errorSubscription: Subscription;

    constructor(protected appState: Store<store.ISharedState>, private errorHandlerService: ErrorHandlerService) {
        this.setupErrorSubscription();
    }
    public ngOnDestroy(): void {
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    }

    public loadFloors(customerId: number, venueId: number): void {
        this.appState.dispatch(new fromFloors.FloorsLoadAction({ customerId: customerId, venueId: venueId }));
    }

    public clearFloors(): void {
        this.appState.dispatch(new fromFloors.FloorsClearAction());
    }

    private setupErrorSubscription(): void {
        this.errorSubscription = this.floorsFailed.subscribe((failed: boolean) => {
            if (failed) { this.errorHandlerService.addToErrors('Something went wrong while loading the floors.'); }
        });
    }
}
