// <copyright file="customers.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CustomerSimpleDTO } from '../../dtos';

@Injectable()
export class CustomersService {
    private baseUrl = 'api/customer';

    constructor(private httpClient: HttpClient) { }

    public getCustomers(): Observable<Array<CustomerSimpleDTO>> {
        return this.httpClient
            .get<Array<CustomerSimpleDTO>>(this.baseUrl + '/GetCustomers');
    }
}
