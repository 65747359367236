// <copyright file="licenses.reducer.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { LicenseDetailsDTO } from './../../../dtos';
import * as actions from '../../actions/licenses/licenses.action';

export interface ILicenseState {
    loading: boolean;
    success: boolean;
    failed: boolean;
    licenses: Array<LicenseDetailsDTO>;
}

export const INITIAL_STATE: ILicenseState = {
    licenses: undefined,
    failed: false,
    loading: false,
    success: false,
};

export function reducer(state = INITIAL_STATE, action: actions.LicensesActions): ILicenseState {
    if (!action)  {
        return state;
    }

    switch (action.type) {
        case actions.licensesActionTypes.LICENSES_LOAD: {
            return Object.assign({}, state, {
                loading: true
            });
        }

        case actions.licensesActionTypes.LICENSES_LOAD_SUCCESS: {
            const loadAction = action as actions.LicensesLoadSuccessAction;
            return Object.assign({}, state, {
                licenses: loadAction.payload,
                success: true,
                failed: false,
                loading: false
            });
        }

        case actions.licensesActionTypes.LICENSES_LOAD_FAIL: {
            return Object.assign({}, state, {
                licenses: undefined,
                success: false,
                failed: true,
                loading: false
            });
        }

        case actions.licensesActionTypes.LICENSES_CLEAR: {
            return Object.assign({}, state, INITIAL_STATE);
        }

        default: {
            return state;
        }
    }
}

export const getLicenses = (state: ILicenseState) => state.licenses;
export const getLoading = (state: ILicenseState) => state.loading;
export const getSuccess = (state: ILicenseState) => state.success;
export const getFailed = (state: ILicenseState) => state.failed;
