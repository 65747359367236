// <copyright file="customers.action.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { CustomerSimpleDTO } from './../../../dtos';
import { Action } from '@ngrx/store';
import { type } from '../../../utility/type/type.helper';

export const customersActionTypes = {
    CUSTOMERS_LOAD: type('[Customers] Load'),
    CUSTOMERS_LOAD_FAIL: type('[Customers] Load Fail'),
    CUSTOMERS_LOAD_SUCCESS: type('[Customers] Load Success'),
};

export class CustomersLoadAction implements Action {
    public type = customersActionTypes.CUSTOMERS_LOAD;

    constructor() { }
}

export class CustomersLoadSuccessAction implements Action {
    public type = customersActionTypes.CUSTOMERS_LOAD_SUCCESS;

    constructor(public payload: Array<CustomerSimpleDTO>) { }
}

export class CustomersLoadFailAction implements Action {
    public type = customersActionTypes.CUSTOMERS_LOAD_FAIL;

    constructor(public payload: any) { }
}

export type CustomersActions
    = CustomersLoadAction
    | CustomersLoadFailAction
    | CustomersLoadSuccessAction;
