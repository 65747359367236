// <copyright file="layouts.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { LayoutSimpleDTO } from '../../dtos';

@Injectable()
export class LayoutsService {
    private baseUrl = 'api/venuedata';

    constructor(private httpClient: HttpClient) { }

    public getLayouts(customerId: number, venueId: number, floorId: number): Observable<Array<LayoutSimpleDTO>> {
        return this.httpClient.get<Array<LayoutSimpleDTO>>(
                this.baseUrl + '/GetLayoutsByCustomerAndVenueAndFloor/' + customerId + '/' + venueId + '/' + floorId);
    }
}
