// <copyright file="index.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { LayoutContainerComponent } from './layout/layout.container';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

export const CONTAINERS = [
    LayoutContainerComponent
];

@NgModule({
    declarations: CONTAINERS,
    exports: CONTAINERS,
    imports: [
        CommonModule,
        RouterModule,
    ],
    providers: []
})
export class ContainersModule { }
