// <copyright file="sdk-licenses.guard.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { SecurityService } from '../../utility/security/security.service';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class CanActivateSdkLicensesRoute implements CanActivate {
    constructor(private securityService: SecurityService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.securityService.canUseSdkApi;
    }
}
