// <copyright file="venues.effect.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { VenuesService } from '../../../services';
import * as venuesActions from '../../actions';

@Injectable()
export class VenuesEffect {
    public doLoadVenuesData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(venuesActions.venuesActionTypes.VENUES_LOAD),
            map((action: venuesActions.VenuesLoadAction) => action.payload),
            mergeMap(state =>
                this.venuesService.getVenues(state.customerId).pipe(
                    map(data => new venuesActions.VenuesLoadSuccessAction(data)),
                    catchError(error => of(new venuesActions.VenuesLoadFailAction(error))),
                ),
            ),
        ),
    );

    constructor(private actions: Actions, private venuesService: VenuesService) {}
}
