// <copyright file="licenses.selectors.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromLicenses from '../../reducers/licenses/licenses.reducer';

export const getLicensesState = (state: fromFeature.ISharedState) => state.licenses;

export const getLicensesData = createSelector(
    getLicensesState,
    fromLicenses.getLicenses
);

export const getLicensesLoading = createSelector(
    getLicensesState,
    fromLicenses.getLoading
);

export const getLicensesSuccess = createSelector(
    getLicensesState,
    fromLicenses.getSuccess
);

export const getLicensesFailed = createSelector(
    getLicensesState,
    fromLicenses.getFailed
);
