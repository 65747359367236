// <copyright file="loading-overlay.component.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Component } from '@angular/core';

@Component({
    selector: 'portal-loading-overlay',
    templateUrl: 'loading-overlay.component.html',
    styleUrls: ['loading-overlay.component.scss']
})

export class LoadingOverlayComponent { }
