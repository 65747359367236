// <copyright file="message.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, filter } from 'rxjs/operators';

interface Message {
    type: string;
    value: string | number;
}

type MessageCallback = (value: string | number) => void;

@Injectable()
export class MessageService {
    private handler = new Subject<Message>();

    broadcast(type: string, value: string | number) {
        this.handler.next({ type, value });
    }

    subscribe(type: string, callback: MessageCallback): Subscription {
        return this.handler
            .pipe(
                filter(message => message.type === type),
                map(message => message.value),
            )
            .subscribe(callback);
    }
}
