// <copyright file="error-handler.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import * as _ from 'lodash';
import { ErrorComponent } from '../../components/error/error.component';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';

@Injectable()
export class ErrorHandlerService {
    private readonly duration = 5000;

    private errors: Array<string> = [];
    private latestMessage: string;
    private snackBarSubscription: Subscription;
    private active = false;

    constructor(private snackBar: MatSnackBar) { }

    public addToErrors(message: string): void {
        if (!_.includes(this.errors, message) && this.latestMessage !== message) {
            this.errors.push(message);
            if (this.errors.length === 1 && !this.active) {
                this.triggerError(this.errors.shift());
            }
        }
    }

    private triggerNextError() {
        if (this.errors.length > 0) {
            this.triggerError(this.errors.shift());
        } else {
            this.latestMessage = '';
        }
    }

    private triggerError(message: string): void {
        this.latestMessage = message;
        this.active = true;
        if (this.snackBarSubscription) { this.snackBarSubscription.unsubscribe(); }
        const snackBarRef = this.snackBar.openFromComponent(ErrorComponent, {
            data: message,
            duration: this.duration
        });
        this.snackBarSubscription = snackBarRef.afterDismissed().subscribe(() => {
            this.active = false;
            this.triggerNextError();
        });
    }
}
