// <copyright file="floors.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FloorSimpleDTO } from '../../dtos';

@Injectable()
export class FloorsService {
    private baseUrl = 'api/venuedata';

    constructor(private httpClient: HttpClient) { }

    public getFloors(customerId: number, venueId: number): Observable<Array<FloorSimpleDTO>> {
        return this.httpClient.get<Array<FloorSimpleDTO>>(this.baseUrl + '/GetFloorsByCustomerAndVenue/' + customerId + '/' + venueId);
    }
}
