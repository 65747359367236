// <copyright file="venues.sandbox.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import * as store from '../../store';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromVenues from '../../store/actions';
import { ErrorHandlerService } from '../../utility/error-handler/error-handler.service';
import { Subscription } from 'rxjs';

@Injectable()
export class VenuesSandbox implements OnDestroy {
    public venues = this.appState.pipe(select(store.getVenuesData));
    public venuesLoading = this.appState.pipe(select(store.getVenuesLoading));
    public venuesFailed = this.appState.pipe(select(store.getVenuesFailed));
    public venuesSuccess = this.appState.pipe(select(store.getVenuesSuccess));

    private errorSubscription: Subscription;

    constructor(protected appState: Store<store.ISharedState>, private errorHandlerService: ErrorHandlerService) {
        this.setupErrorSubscription();
    }

    public ngOnDestroy(): void {
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    }

    public loadVenues(customerId: number): void {
        this.appState.dispatch(new fromVenues.VenuesLoadAction({ customerId: customerId }));
    }

    public clearVenues(): void {
        this.appState.dispatch(new fromVenues.VenuesClearAction());
    }

    private setupErrorSubscription(): void {
        this.errorSubscription = this.venuesFailed.subscribe((failed: boolean) => {
            if (failed) { this.errorHandlerService.addToErrors('Something went wrong while loading the venues.'); }
        });
    }
}
