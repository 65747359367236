// <copyright file="localStorage.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
@Injectable()
export class LocalStorageService {
    public setObject(key: string, value: number|string ): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
    public getObject(key: string): string {
        return JSON.parse(localStorage.getItem(key));
    }
}

