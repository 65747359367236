// <copyright file="venues.selectors.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromVenues from '../../reducers/venues/venues.reducer';

export const getVenuesState =  (state: fromFeature.ISharedState) => state.venues;

export const getVenuesData = createSelector(
    getVenuesState,
    fromVenues.getVenues
);

export const getVenuesLoading = createSelector(
    getVenuesState,
    fromVenues.getLoading
);

export const getVenuesSuccess = createSelector(
    getVenuesState,
    fromVenues.getSuccess
);

export const getVenuesFailed = createSelector(
    getVenuesState,
    fromVenues.getFailed
);
