// <copyright file="layouts.effect.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LayoutsService } from '../../../services';
import * as layoutsActions from '../../actions';

@Injectable()
export class LayoutsEffect {
    public doLoadLayoutsData: Observable<Action> = createEffect(() =>
        this.actions.pipe(
            ofType(layoutsActions.layoutsActionTypes.LAYOUTS_LOAD),
            map((action: layoutsActions.LayoutsLoadAction) => action.payload),
            mergeMap(state =>
                this.layoutsService.getLayouts(state.customerId, state.venueId, state.floorId).pipe(
                    map(data => new layoutsActions.LayoutsLoadSuccessAction({ data: data, floorId: state.floorId })),
                    catchError(error => of(new layoutsActions.LayoutsLoadFailAction(error))),
                ),
            ),
        ),
    );

    constructor(private actions: Actions, private layoutsService: LayoutsService) {}
}
