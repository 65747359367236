// <copyright file="venues.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { VenueSimpleDTO } from '../../dtos';

@Injectable()
export class VenuesService {
    private baseUrl = 'api/customer';

    constructor(private httpClient: HttpClient) { }

    public getVenues(customerId: number): Observable<Array<VenueSimpleDTO>> {
        return this.httpClient
            .get<Array<VenueSimpleDTO>>(this.baseUrl + '/GetVenuesByCustomer/' + customerId);
    }
}
