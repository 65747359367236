// <copyright file="toolbar-menu.component.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Component, ElementRef } from '@angular/core';
import { MsalService } from 'shared/msal';

import { ConfigurationService } from '../../utility/configuration/configuration.service';
import { SecurityService } from '../../utility/security/security.service';

interface NavMenuItem {
    routerLink: string;
    description: string;
}

@Component({
    selector: 'portal-toolbar-menu',
    styleUrls: ['./toolbar-menu.component.scss'],
    templateUrl: './toolbar-menu.component.html',
})
export class ToolbarMenuComponent {
    private linkSdkLogs: NavMenuItem = { routerLink: '/sdkLogs', description: 'SDK Logs' };
    private linkSdkApiFiles: NavMenuItem = { routerLink: '/sdkApiFiles', description: 'SDK Api Files' };
    private linkSdkLicenses: NavMenuItem = { routerLink: '/sdkLicenses', description: 'SDK Licenses' };
    private linkVenueData: NavMenuItem = { routerLink: '/venuedata', description: 'Venue Data' };

    constructor(
        private configurationService: ConfigurationService,
        private securityService: SecurityService,
        private msalService: MsalService,
    ) {
        this.msalService.handleRedirectObservable().subscribe({
            next: result => {},
            error: error => {},
        });
    }

    public get NavMenuItems(): NavMenuItem[] {
        const list: NavMenuItem[] = [];

        if (this.securityService.canReadSdkLogs) {
            list.push(this.linkSdkLogs);
        }
        if (this.securityService.canUseSdkApi || this.securityService.canUploadSdkApi) {
            list.push(this.linkSdkApiFiles);
        }
        if (this.securityService.canUseSdkApi) {
            list.push(this.linkSdkLicenses);
        }
        if (this.securityService.canDownloadVenueData) {
            list.push(this.linkVenueData);
        }
        return list;
    }

    public get canAuthenticate(): boolean {
        return this.securityService.isInitialized;
    }

    public get isAuthenticated(): boolean {
        return this.securityService.isAuthenticated;
    }

    public get showEnvironmentBanner(): boolean {
        return this.configurationService.environment != null && this.configurationService.environment !== 'prd';
    }

    public get environment(): string {
        return this.configurationService.environment;
    }

    public get userName(): string {
        return this.securityService.userInfo.username;
    }

    public logIn() {
        this.securityService.logIn();
    }

    public logOut() {
        this.securityService.logOut();
    }

    public getAuthenticatedUser(): string {
        return this.securityService.userInfo.username;
    }
}
