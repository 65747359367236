// <copyright file="floors.selectors.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../reducers';
import * as fromFloors from '../../reducers/floors/floors.reducer';

export const getFloorsState = (state: fromFeature.ISharedState) => state.floors;

export const getFloorsData = createSelector(
    getFloorsState,
    fromFloors.getFloors
);

export const getFloorsLoading = createSelector(
    getFloorsState,
    fromFloors.getLoading
);

export const getFloorsSuccess = createSelector(
    getFloorsState,
    fromFloors.getSuccess
);

export const getFloorsFailed = createSelector(
    getFloorsState,
    fromFloors.getFailed
);
