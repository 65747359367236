// <copyright file="licenses.sandbox.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import * as store from '../../store';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromLicenses from '../../store/actions';
import { ErrorHandlerService } from '../../utility/error-handler/error-handler.service';
import { Subscription } from 'rxjs';

@Injectable()
export class LicensesSandbox implements OnDestroy {
    public licenses = this.appState.pipe(select(store.getLicensesData));
    public licensesLoading = this.appState.pipe(select(store.getLicensesLoading));
    public licensesFailed = this.appState.pipe(select(store.getLicensesFailed));
    public licensesSuccess = this.appState.pipe(select(store.getLicensesSuccess));

    private errorSubscription: Subscription;

    constructor(protected appState: Store<store.ISharedState>, private errorHandlerService: ErrorHandlerService) {
        this.setupErrorSubscription();
    }

    public ngOnDestroy(): void {
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    }

    public loadLicenses(customerId: number, venueId: number): void {
        this.appState.dispatch(new fromLicenses.LicensesLoadAction({ customerId: customerId, venueId: venueId }));
    }

    public clearLicenses(): void {
        this.appState.dispatch(new fromLicenses.LicensesClearAction());
    }

    private setupErrorSubscription(): void {
        this.errorSubscription = this.licensesFailed.subscribe((failed: boolean) => {
            if (failed) { this.errorHandlerService.addToErrors('Something went wrong while loading the licenses.'); }
        });
    }
}
