// <copyright file="security.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MsalService } from 'shared/msal';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from 'shared/msal/constants';
import { MsalGuardConfiguration } from 'shared/msal/msal.guard.config';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { MsalInterceptorConfig } from 'shared/msal/msal.interceptor.config';
import { Minimatch } from 'minimatch';

@Injectable()
export class SecurityService {
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfig,
        public msalService: MsalService,
    ) {}

    public get canReadSdkLogs(): boolean {
        return (
            this.hasRoles() &&
            (this.getRoles().indexOf(this.applicationRoles.Administrator) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.InternalDeveloper) !== -1)
        );
    }

    public get canUseSdkApi(): boolean {
        return (
            this.hasRoles() &&
            (this.getRoles().indexOf(this.applicationRoles.Administrator) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.InternalDeveloper) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.ExternalDeveloper) !== -1)
        );
    }

    public get canUploadSdkApi(): boolean {
        return (
            this.hasRoles() &&
            (this.getRoles().indexOf(this.applicationRoles.Administrator) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.InternalDeveloper) !== -1)
        );
    }
    public get canDeleteSdkApi(): boolean {
        return (
            this.hasRoles() &&
            (this.getRoles().indexOf(this.applicationRoles.Administrator) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.InternalDeveloper) !== -1)
        );
    }

    public get canGetSdkLicense(): boolean {
        return (
            this.hasRoles() &&
            (this.getRoles().indexOf(this.applicationRoles.Administrator) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.InternalDeveloper) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.ExternalDeveloper) !== -1)
        );
    }

    public get canDownloadVenueData(): boolean {
        return (
            this.hasRoles() &&
            (this.getRoles().indexOf(this.applicationRoles.Administrator) !== -1 ||
                this.getRoles().indexOf(this.applicationRoles.InternalDeveloper) !== -1)
        );
    }

    public initializedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    public isInitialized = false;

    public get userInfo(): AccountInfo {
        return this.msalService.getAllAccounts()[0];
    }

    private applicationRoles = {
        Administrator: 'Administrator',
        InternalDeveloper: 'InternalDeveloper',
        ExternalDeveloper: 'ExternalDeveloper',
    };

    public acquireToken(): Observable<AuthenticationResult> {
        const scopes = this.getScopesForEndpoint(window.location.host);
        const account = this.msalService.getAllAccounts()[0];
        return this.msalService.acquireTokenSilent({scopes, account});
    }

    public get isAuthenticated(): boolean {
        return Boolean(this.msalService.getAllAccounts().length);
    }

    public logIn() {
        this.msalService.loginRedirect({
            ...this.msalGuardConfig.authRequest,
        });
    }

    public logOut() {
        if (this.msalService.getAllAccounts().length) {
            this.msalService.logout();
        }
    }

    private hasRoles(): boolean {
        return (
            this.userInfo !== undefined &&
            this.getRoles() !== undefined
        );
    }

    private getRoles(): string[] {
        return (this.msalService.getAllAccounts()[0].idTokenClaims as any).roles;
    }

    private getScopesForEndpoint(endpoint: string): Array<string>|null {
        const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
        const keyMatchesEndpointArray = protectedResourcesArray.filter(key => {
            const minimatch = new Minimatch(key);
            return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
        });

        // process all protected resources and send the first matched resource
        if (keyMatchesEndpointArray.length > 0) {
            const keyForEndpoint = keyMatchesEndpointArray[0];
            if (keyForEndpoint) {
                return this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
            }
        }

        return null;
    }
}
