// <copyright file="configuration.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { ConfigurationV2 } from 'sdklicenses/shared/models/configuration/configuration.model';
import { MsalInterceptorConfig } from 'shared/msal/msal.interceptor.config';

@Injectable()
export class ConfigurationService {
    public adal: { instance: string, tenant: string, clientId: string };
    public environment: string;
    public baseUrl: string;
    public warningPeriodInSeconds: number;

    constructor() {
        // The code below is sync on prupose. The configuration is needed on the start
        // of the MsalModule. Without this we don't have security.
        // Currently there are now other ways while also using AOT.
        // For more information please see:
        // https://github.com/angular/angular/issues/23279

        const httpReq = new XMLHttpRequest();
        httpReq.open('GET', '/api/configuration', false);
        httpReq.send();

        if (httpReq.status === 200) {
            environment.config = JSON.parse(httpReq.responseText) as ConfigurationV2;
        } else {
            console.error('Initialization error', httpReq.responseText);
        }
     }

     public getMSALInstanceFactory(): PublicClientApplication {
        const config = environment.config;
        return new PublicClientApplication({
            auth: {
                clientId: config.aad.clientId,
                redirectUri: window.location.origin + '/',
                authority: config.aad.authority,
            },
            cache: {
                cacheLocation: 'sessionStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            }
        });
    }

    public getMSALInterceptorConfigFactory(): MsalInterceptorConfig {
        const protectedResourceMap = this.getProtectedResources();
        const appScopes = protectedResourceMap.map(a => a[1]).reduce((a, b) => a.concat(b), []);
        return {
            interactionType: InteractionType.Popup,
            protectedResourceMap: new Map(protectedResourceMap),
        };
    }

    private getProtectedResources(): [string, string[]][] {
        const config = environment.config;
        return [
            [`${window.location.origin}/assets/**`, null],
            [`${window.location.origin}`, config.services?.developerPortal?.scopes],
        ];
    }
}
