// <copyright file="app.component.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Component, OnInit } from '@angular/core';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from 'shared/msal';
import { SecurityService } from './shared/utility/security/security.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _broadCastFailureSubscription: Subscription;
    private readonly _destroying$ = new Subject<void>();
    constructor(
        private securityService: SecurityService,
        private msalBroadcastService: MsalBroadcastService,
    ) {}

    ngOnInit(): void {
        this._broadCastFailureSubscription = this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS,
                ),
                takeUntil(this._destroying$),
            )
            .subscribe(
                result => {},
                error => {},
            );
    }

    public get isAuthenticated(): boolean {
        return this.securityService.isAuthenticated;
    }
}
