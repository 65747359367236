// <copyright file="type.helper.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

const typeCache: { [label: string]: boolean } = {};
/**
 * Source: https://github.com/netmedia/angular-architecture-patterns/blob/master/src/app/shared/utility/utility.service.ts
 */


/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels are unique.
 *
 * @param label
 */
export function type<T>(label: T | ''): T {
    if (typeCache[<string>label]) {
        throw new Error(`Action type '${label}' is not unqiue`);
    }

    typeCache[<string>label] = true;

    return <T>label;
}
