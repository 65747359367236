// <copyright file="customer-information.service.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Injectable } from '@angular/core';
import { CustomersSandbox } from '../../sandboxes/customers/customers.sandbox';
import { VenuesSandbox } from '../../sandboxes/venues/venues.sandbox';
import { FloorsSandbox } from '../../sandboxes/floors/floors.sandbox';
import { LicensesSandbox } from '../../sandboxes/licenses/licenses.sandbox';
import { LayoutsSandbox } from '../../sandboxes/layouts/layouts.sandbox';

@Injectable()
export class CustomerInformationService {
    constructor(
        public customersSandbox: CustomersSandbox,
        public venuesSandbox: VenuesSandbox,
        public floorsSandbox: FloorsSandbox,
        public licensesSandbox: LicensesSandbox,
        public layoutsSandbox: LayoutsSandbox) { }

    public selectCustomer(customerId: number): void {
        this.venuesSandbox.clearVenues();
        this.licensesSandbox.clearLicenses();
        this.floorsSandbox.clearFloors();
        this.layoutsSandbox.clearLayouts();

        this.venuesSandbox.loadVenues(customerId);
    }

    public selectVenue(customerId: number, venueId: number, loadFloors = true): void {
        this.licensesSandbox.clearLicenses();
        this.floorsSandbox.clearFloors();
        this.layoutsSandbox.clearLayouts();

        this.licensesSandbox.loadLicenses(customerId, venueId);
        if (loadFloors) {
            this.floorsSandbox.loadFloors(customerId, venueId);
        }
    }

    public switchComponents() {
        this.venuesSandbox.clearVenues();
        this.licensesSandbox.clearLicenses();
        this.floorsSandbox.clearFloors();
        this.layoutsSandbox.clearLayouts();
    }
}
