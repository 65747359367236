// <copyright file="customers.reducer.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { CustomerSimpleDTO } from './../../../dtos';
import * as actions from '../../actions/customers/customers.action';

export interface ICustomerState {
    loading: boolean;
    success: boolean;
    failed: boolean;
    customers: Array<CustomerSimpleDTO>;
}

export const INITIAL_STATE: ICustomerState = {
    customers: [],
    failed: false,
    loading: false,
    success: false,
};

export function reducer(state = INITIAL_STATE, action: actions.CustomersActions): ICustomerState {
    if (!action)  {
        return state;
    }

    switch (action.type) {
        case actions.customersActionTypes.CUSTOMERS_LOAD: {
            return Object.assign({}, state, {
                loading: true
            });
        }

        case actions.customersActionTypes.CUSTOMERS_LOAD_SUCCESS: {
            const loadAction = action as actions.CustomersLoadSuccessAction;
            return Object.assign({}, state, {
                customers: loadAction.payload,
                success: true,
                failed: false,
                loading: false
            });
        }

        case actions.customersActionTypes.CUSTOMERS_LOAD_FAIL: {
            return Object.assign({}, state, {
                customers: [],
                success: false,
                failed: true,
                loading: false
            });
        }

        default: {
            return state;
        }
    }
}

export const getCustomers = (state: ICustomerState) => state.customers;
export const getLoading = (state: ICustomerState) => state.loading;
export const getSuccess = (state: ICustomerState) => state.success;
export const getFailed = (state: ICustomerState) => state.failed;
