// <copyright file="venues.action.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { VenueSimpleDTO } from './../../../dtos';
import { Action } from '@ngrx/store';
import { type } from '../../../utility/type/type.helper';

export const venuesActionTypes = {
    VENUES_LOAD: type('[Venues] Load'),
    VENUES_LOAD_FAIL: type('[Venues] Load Fail'),
    VENUES_LOAD_SUCCESS: type('[Venues] Load Success'),
    VENUES_CLEAR: type('[Venues] Clear')
};

export class VenuesLoadAction implements Action {
    public type = venuesActionTypes.VENUES_LOAD;

    constructor(public payload: { customerId: number }) { }
}

export class VenuesLoadSuccessAction implements Action {
    public type = venuesActionTypes.VENUES_LOAD_SUCCESS;

    constructor(public payload: Array<VenueSimpleDTO>) { }
}

export class VenuesLoadFailAction implements Action {
    public type = venuesActionTypes.VENUES_LOAD_FAIL;

    constructor(public payload: any) { }
}

export class VenuesClearAction implements Action {
    public type = venuesActionTypes.VENUES_CLEAR;

    constructor() { }
}

export type VenuesActions
    = VenuesLoadAction
    | VenuesLoadFailAction
    | VenuesLoadSuccessAction
    | VenuesClearAction;
