// <copyright file="licenses.action.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { LicenseDetailsDTO } from './../../../dtos';
import { Action } from '@ngrx/store';
import { type } from '../../../utility/type/type.helper';

export const licensesActionTypes = {
    LICENSES_LOAD: type('[Licenses] Load'),
    LICENSES_LOAD_FAIL: type('[Licenses] Load Fail'),
    LICENSES_LOAD_SUCCESS: type('[Licenses] Load Success'),
    LICENSES_CLEAR: type('[Licenses] Clear')
};

export class LicensesLoadAction implements Action {
    public type = licensesActionTypes.LICENSES_LOAD;

    constructor(public payload: { customerId: number, venueId: number }) { }
}

export class LicensesLoadSuccessAction implements Action {
    public type = licensesActionTypes.LICENSES_LOAD_SUCCESS;

    constructor(public payload: Array<LicenseDetailsDTO>) { }
}

export class LicensesLoadFailAction implements Action {
    public type = licensesActionTypes.LICENSES_LOAD_FAIL;

    constructor(public payload: any) { }
}

export class LicensesClearAction implements Action {
    public type = licensesActionTypes.LICENSES_CLEAR;

    constructor() { }
}

export type LicensesActions
    = LicensesLoadAction
    | LicensesLoadFailAction
    | LicensesLoadSuccessAction
    | LicensesClearAction;
