// <copyright file="layouts.sandbox.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import * as store from '../../store';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromLayouts from '../../store/actions';
import { ErrorHandlerService } from '../../utility/error-handler/error-handler.service';
import { Subscription } from 'rxjs';

@Injectable()
export class LayoutsSandbox implements OnDestroy {
    public layouts = this.appState.pipe(select(store.getLayoutsData));
    public layoutsLoading = this.appState.pipe(select(store.getLayoutsLoading));
    public layoutsFailed = this.appState.pipe(select(store.getLayoutsFailed));
    public layoutsSuccess = this.appState.pipe(select(store.getLayoutsSuccess));

    private errorSubscription: Subscription;

    constructor(protected appState: Store<store.ISharedState>, private errorHandlerService: ErrorHandlerService) {
        this.setupErrorSubscription();
    }

    public ngOnDestroy(): void {
        if (this.errorSubscription) {
            this.errorSubscription.unsubscribe();
        }
    }

    public loadLayouts(customerId: number, venueId: number, floorId: number): void {
        this.appState.dispatch(new fromLayouts.LayoutsLoadAction({ customerId: customerId, venueId: venueId, floorId: floorId }));
    }

    public clearLayouts(): void {
        this.appState.dispatch(new fromLayouts.LayoutsClearAction());
    }

    private setupErrorSubscription(): void {
        this.errorSubscription = this.layoutsFailed.subscribe((failed: boolean) => {
            if (failed) { this.errorHandlerService.addToErrors('Something went wrong while loading the layouts.'); }
        });
    }
}
