// <copyright file="layouts.action.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { LayoutSimpleDTO } from './../../../dtos';
import { Action } from '@ngrx/store';
import { type } from '../../../utility/type/type.helper';

export const layoutsActionTypes = {
    LAYOUTS_LOAD: type('[Layouts] Load'),
    LAYOUTS_LOAD_FAIL: type('[Layouts] Load Fail'),
    LAYOUTS_LOAD_SUCCESS: type('[Layouts] Load Success'),
    LAYOUTS_CLEAR: type('[Layouts] Clear')
};

export class LayoutsLoadAction implements Action {
    public type = layoutsActionTypes.LAYOUTS_LOAD;

    constructor(public payload: { customerId: number, venueId: number, floorId: number }) { }
}

export class LayoutsLoadSuccessAction implements Action {
    public type = layoutsActionTypes.LAYOUTS_LOAD_SUCCESS;

    constructor(public payload: { data: Array<LayoutSimpleDTO>, floorId: number }) { }
}

export class LayoutsLoadFailAction implements Action {
    public type = layoutsActionTypes.LAYOUTS_LOAD_FAIL;

    constructor(public payload: any) { }
}

export class LayoutsClearAction implements Action {
    public type = layoutsActionTypes.LAYOUTS_CLEAR;

    constructor() { }
}

export type LayoutsActions
    = LayoutsLoadAction
    | LayoutsLoadFailAction
    | LayoutsLoadSuccessAction
    | LayoutsClearAction;
