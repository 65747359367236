// <copyright file="error.component.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'cp-error-component',
    styleUrls: ['error.component.scss'],
    templateUrl: 'error.component.html'
})

export class ErrorComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}
