// <copyright file="page-not-found.component.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { Component } from '@angular/core';

@Component({
    selector: 'portal-page-not-found',
    templateUrl: 'page-not-found.component.html',
    styleUrls: ['page-not-found.component.scss']
})

export class PageNotFoundComponent { }
