// <copyright file="floors.reducer.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { FloorSimpleDTO } from './../../../dtos';
import * as actions from '../../actions/floors/floors.action';

export interface IFloorState {
    loading: boolean;
    success: boolean;
    failed: boolean;
    floors: Array<FloorSimpleDTO>;
}

export const INITIAL_STATE: IFloorState = {
    floors: undefined,
    failed: false,
    loading: false,
    success: false,
};

export function reducer(state = INITIAL_STATE, action: actions.FloorsActions): IFloorState {
    if (!action)  {
        return state;
    }

    switch (action.type) {
        case actions.floorsActionTypes.FLOORS_LOAD: {
            return Object.assign({}, state, {
                loading: true
            });
        }

        case actions.floorsActionTypes.FLOORS_LOAD_SUCCESS: {
            const loadAction = action as actions.FloorsLoadSuccessAction;
            return Object.assign({}, state, {
                floors: loadAction.payload,
                success: true,
                failed: false,
                loading: false
            });
        }

        case actions.floorsActionTypes.FLOORS_LOAD_FAIL: {
            return Object.assign({}, state, {
                floors: undefined,
                success: false,
                failed: true,
                loading: false
            });
        }

        case actions.floorsActionTypes.FLOORS_CLEAR: {
            return Object.assign({}, state, INITIAL_STATE);
        }

        default: {
            return state;
        }
    }
}

export const getFloors = (state: IFloorState) => state.floors;
export const getLoading = (state: IFloorState) => state.loading;
export const getSuccess = (state: IFloorState) => state.success;
export const getFailed = (state: IFloorState) => state.failed;
