// <copyright file="layouts.reducer.ts" company="Signify Holding">
//     Copyright © 2015 - 2021, All Rights Reserved.
// </copyright>

import { LayoutSimpleDTO, FloorSimpleDTO } from './../../../dtos';
import * as actions from '../../actions/layouts/layouts.action';
import * as Collections from 'typescript-collections';
import * as _ from 'lodash';

export interface ILayoutState {
    loading: boolean;
    success: boolean;
    failed: boolean;
    layouts: Collections.Dictionary<number, Array<LayoutSimpleDTO>>;
}

export const INITIAL_STATE: ILayoutState = {
    layouts: new Collections.Dictionary<number, Array<LayoutSimpleDTO>>(),
    failed: false,
    loading: false,
    success: false,
};

export function reducer(state = INITIAL_STATE, action: actions.LayoutsActions): ILayoutState {
    if (!action) {
        return state;
    }

    switch (action.type) {
        case actions.layoutsActionTypes.LAYOUTS_LOAD: {
            return Object.assign({}, state, {
                loading: true
            });
        }

        case actions.layoutsActionTypes.LAYOUTS_LOAD_SUCCESS: {
            const loadAction = action as actions.LayoutsLoadSuccessAction;
            const subLayouts = state.layouts;
            subLayouts.setValue(loadAction.payload.floorId, loadAction.payload.data);

            return Object.assign({}, state, {
                layouts: subLayouts,
                success: true,
                failed: false,
                loading: false
            });
        }

        case actions.layoutsActionTypes.LAYOUTS_LOAD_FAIL: {
            return Object.assign({}, state, {
                layouts: new Collections.Dictionary<number, Array<LayoutSimpleDTO>>(),
                success: false,
                failed: true,
                loading: false
            });
        }

        case actions.layoutsActionTypes.LAYOUTS_CLEAR: {
            const newState = Object.assign({}, state, {
                layouts: new Collections.Dictionary<number, Array<LayoutSimpleDTO>>(),
                failed: false,
                loading: false,
                success: false,
            });
            return newState;
        }

        default: {
            return state;
        }
    }
}

export const getLayouts = (state: ILayoutState) => state.layouts;
export const getLoading = (state: ILayoutState) => state.loading;
export const getSuccess = (state: ILayoutState) => state.success;
export const getFailed = (state: ILayoutState) => state.failed;
